<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Анкета автора</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage" class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <settings-author-form></settings-author-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SettingsAuthorForm from "./SettingsAuthorForm.vue";
export default {
  components: { SettingsAuthorForm },
  data: () => ({
    

  }),
  methods: {
   

  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      pass: "account/pass",
    }),
   
  },

};
</script>
